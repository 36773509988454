<template>
  <master-detail
    :actionBarButtons="actionBarButtons"
    :canEdit="false"
    :cols="cols"
    :contextOptions="contextOptions"
    :customResource="customResource"
    :filterQuery="filterQuery"
    :hasFilter="true"
    :hasNewButton="false"
    :opts="opts"
    @mounted="doLoad = $event.doLoad"
  >
    <span v-if="nomeColaborador">
      <b>Consultor(a): </b>{{ nomeColaborador }}
    </span>
  </master-detail>
</template>

<script>
import { BeneficiosEnum, BeneficiosLabels } from "@/core/enums/beneficios";
import { mapGetters } from "vuex";
import { AnyAdmin, UserTypeEnum } from "@/core/enums/user-types";

export default {
  name: "clientes",
  components: {
    "master-detail": () => import("@/components/master-detail.vue"),
  },
  computed: {
    ...mapGetters(["selectedClient", "user"]),
    actionBarButtons: function () {
      return this.userHasAccessToAction("create")
        ? [
            {
              text: "Adicionar",
              icon: "mdi-plus-box-outline",
              action: () => {
                this.$router.push({ name: "cadastro-empresa" });
              },
            },
          ]
        : [];
    },
    cols: function () {
      return [
        { key: "id", name: "ID", size: 100, sort: 0, show: true },
        {
          key: "fantasia",
          name: "Razão Social",
          size: 0,
          sort: 0,
          show: true,
        },
        { key: "cnpj", name: "CNPJ", size: 250, sort: 0, show: true , nowrap: true },
        { key: "ativo", name: "Ativo", sort: 0, show: true , nowrap: true, rel: { to: 'ativo', key: 'id', name: 'name' } },
        {
          key: "consultorId",
          name: "Consultor Tributário",
          size: 300,
          sort: 0,
          show: true,
          rel: { to: "consultores", key: "id", name: "nome" },
        },
        {
          key: "consultorProjetosId",
          name: "Consultor de Projetos",
          size: 300,
          sort: 0,
          show: true,
          rel: { to: "consultores", key: "id", name: "nome" },
        },
        {
          key: "trabalho",
          name: "Benefício",
          size: 150,
          sort: 0,
          show: true,
          rel: { to: "trabalho", key: "id", name: "nome" },
        },
        {
          key: "periodicidade",
          name: "Periodicidade",
          size: 150,
          sort: 0,
          show: true,
          rel: { to: "periodicidade", key: "id", name: "nome" },
        },
        {
          key: "modificado_em",
          name: "Modificado Em",
          size: 150,
          sort: 0,
          show: true,
          type: this.$fieldTypes.DATETIME,
        },
      ];
    },
    contextOptions: function () {
      const options = [];

      if (this.userHasAccess("Colaboradores.resource")) {
        options.push({
          name: "Colaboradores",
          cb: (row) => {
            this.$router.push({ name: "listagem-colaboradores", params: { clientId: row.id } });
          },
        });
      }

      if (this.userHasAccess("Cadastro.usuarios")) {
        options.push(
        {
          name: "Usuários do sistema",
          limit: 1,
          cb: (row) => {
            this.$router.push({ name: "listagem-usuarios", params: { clientId: row.id } });
          },
        });
      }

      if (this.userHasAccessToAction("update") || this.doActions) {
        options.push({
          name: "Editar",
          limit: 1,
          cb: (row) => {
            this.$router.push({ name: "edicao-empresa", params: { empresaId: row.id } });
          },
        });
      }


      return options;
    },
    customResource: function () {
      const resource = this.apiResource("v1/clientes");
      const id = this.selectedClient?.id;
      return {
        ...resource,
        get(arg) {
          if (id) {
            return resource.get({ ...arg, id }).then((response) => response.error ? response : [response]);
          }

          return resource.get(arg);
        },
      }
    },
    filterQuery: function () {
      const { consultorId } = this.$route.query;

      if (consultorId) {
        return `consultorId=${consultorId}`;
      }

      return null;
    },
    nomeColaborador: function () {
      const { consultorId } = this.$route.query;
      const { consultores } = this.opts;

      if (!consultorId || !Array.isArray(consultores)) {
        return null;
      }

      const consultor = consultores.find(({ id }) => id === (consultorId * 1));
      return consultor?.nome || "nome não encontrado";
    },
    resourceConsultores: function () {
      return this.apiResource("v1/consultores");
    },
    doActions: function() {
      return AnyAdmin.includes(this.user.tipo_usuario) || [UserTypeEnum.CLIENTE_ADMINISTRADOR,].includes(this.user.tipo_usuario)
    }
  },
  created: function () {
    this.resourceConsultores.get().then((response) => {
      if (!response.error) {
        this.opts.consultores = response.consultores;
      }
    });
  },
  data: function () {
    return {
      doLoad: () => {},
      opts: {
        consultores: [],
        trabalho: [
          {
            id: BeneficiosEnum.LEI_DO_BEM,
            nome: BeneficiosLabels.LEI_DO_BEM,
          },
          {
            id: BeneficiosEnum.LEI_DE_INFORMATICA,
            nome: BeneficiosLabels.LEI_DE_INFORMATICA,
          },
          {
            id: BeneficiosEnum.LEI_DO_BEM_E_INFORMATICA,
            nome: BeneficiosLabels.LEI_DO_BEM_E_INFORMATICA,
          },
        ],
        periodicidade: [
          {
            id: null,
            nome: "Indefinido",
          },
          {
            id: 0,
            nome: "Indefinido",
          },
          {
            id: 1,
            nome: "Mensal",
          },
          {
            id: 2,
            nome: "Bimestral",
          },
          {
            id: 3,
            nome: "Trimestral",
          },
          {
            id: 6,
            nome: "Semestral",
          },
          {
            id: 12,
            nome: "Anual",
          },
        ],
        ativo: [
          {id: 'S', name: 'Sim'},
          {id: 'N', name: 'Não'},
        ]
      },
    };
  },
  watch: {
    "selectedClient": function () {
      if (this.doLoad) {
        setTimeout(this.doLoad, 200);
      }
    }
  }
};
</script>
